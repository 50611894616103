<script>
import Swal from "sweetalert2";

import Layout from "../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      doctor: {
        val: "",
        isValid: true,
      },
      appointment: {
        val: "",
        isValid: true,
      },
      reason: {
        val: "",
        isValid: true,
      },
      time: {
        val: "",
        isValid: true,
      },
      date: {
        val: "",
        isValid: true,
      },
      doctorComments: {
        val: "",
        isValid: true,
      },
      status: {
        val: "new",
        isValid: true,
      },
      appointments: [],
      id: "",
      formIsValid: true,
      startDate: null,
      endDate: null,
      booked: [],
      dates: null,
      docData: [],
      user: [],
      startTime: '',
      endTime: '',
      timeSlots: [],
      dId: null,
      uId: null,
      day: '',
      selectedSlot: "",
      config: []
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true
    },
    reloadPage() {
      window.location.reload();
    },
    validateForm() {
      if (this.doctor.val === "") {
        this.doctor.isValid = false;
        this.formIsValid = false;
      }
      if (this.appointment.val === "") {
        this.appointment.isValid = false;
        this.formIsValid = false;
      }
      if (this.reason.val === "") {
        this.reason.isValid = false;
        this.formIsValid = false;
      }
      if (this.time.val === "") {
        this.time.isValid = false;
        this.formIsValid = false;
      }
      if (this.date.val === "") {
        this.date.isValid = false;
        this.formIsValid = false;
      }
      if (this.doctorComments.val === "") {
        this.doctorComments.isValid = false;
        this.formIsValid = false;
      }
      if (this.status.val === "") {
        this.status.isValid = false;
        this.formIsValid = false;
      }
      else {
        this.formIsValid = true;
      }
    },
    async getAppointment() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/appointment/"
      );
      this.appointments = response.data;
      const lastEl = this.appointments[this.appointments.length - 1];
      this.id = lastEl.id;
      this.successmsg();
    },
    async getDoctor() {
      const res = await axios.get("https://api.doctosoft.com/index.php/api/doctor/")
      this.docData = res.data
    },
    async getUser() {
      const res = await axios.get("https://api.doctosoft.com/index.php/api/user/")
      this.user = res.data;
    },
    async getBookedSlot() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/book/appointment/" + this.dId + '/' + this.dates
      );
      this.booked = response.data;
      this.getSlots();
    },
    async getSlots() {
      const response = await axios.get("https://api.doctosoft.com/index.php/api/app/config/");
      this.config = response.data.config;
      console.log(this.config.day)

      let currentDate = new Date();
    let nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + parseInt(this.config.day));
    console.log(nextDate)
    let startYear = currentDate.getFullYear();
    let startMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let startDay = currentDate.getDate().toString().padStart(2, "0");
    let endYear = nextDate.getFullYear();
    let endMonth = (nextDate.getMonth() + 1).toString().padStart(2, "0");
    let endDay = nextDate.getDate().toString().padStart(2, "0");
    this.startDate = `${startYear}-${startMonth}-${startDay}`;
    this.endDate = `${endYear}-${endMonth}-${endDay}`;

      this.timeSlots = [];

      const startTime = this.config.startTime;
      const endTime = this.config.endTime;
      const timeSlotDuration = parseInt(this.config.interval); // duration of each time slot in minutes

      let currentTime = startTime;

      while (currentTime < endTime) {
        const start = currentTime;
        const end = addMinutes(currentTime, timeSlotDuration);
        if (end > endTime) {
          break;
        }
        const timeSlot = start + "-" + end;
        this.timeSlots.push(timeSlot);

        currentTime = end;

      }
      function addMinutes(time, minutes) {
        const [hours, mins] = time.split(":").map(Number);
        const timeInMinutes = hours * 60 + mins + minutes;
        const newHours = Math.floor(timeInMinutes / 60);
        const newMins = timeInMinutes % 60;
        return `${newHours.toString().padStart(2, "0")}:${newMins
          .toString()
          .padStart(2, "0")}`;
      }
    },
    async addAppointment() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("doctor", this.dId);
        formdata.append("user", this.uId);
        formdata.append("reason", this.reason.val);
        formdata.append("time", this.selectedSlot);
        formdata.append("date", this.dates);
        formdata.append("doctorComments", this.doctorComments.val);
        formdata.append("status", this.status.val);

        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/appointment",
          formdata
        );
        this.msg = result.data;
        this.getAppointment();
        this.$router.push("/admin/appointment");
      }
    },

    errormsg() {
      Swal.fire(
        "Invalid Form",
        "Please fix the errors and try again later",
        "error"
      );
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new Appointment successfully",
        "success"
      );
    },
  },

  created() {
    this.getSlots()
    
    
    this.getDoctor()
    this.getUser()

  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/appointment')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/appointment')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addAppointment">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addAppointment">

            <div class="mb-3" :class="{ invalid: !doctor.isValid }">
              <label for="doctor" class="form-label">Doctor</label>
              <select class="form-select" aria-label="size 3 select example" v-model="dId" @blur="clearValidity('doctor')"
                @change="getBookedSlot()">
                <option v-for="doc in docData" :key="doc.id" :value="doc.id">
                  {{ doc.name }}
                </option>
              </select>
            </div>

            <div class="mb-3" :class="{ invalid: !appointment.isValid }">
              <label for="appointment" class="form-label">Name</label>
              <select class="form-select" aria-label="size 3 select example" v-model="uId"
                @blur="clearValidity('appointment')" @change="getUser()">
                <option v-for="users in user" :key="users.id" :value="users.id">
                  {{ users.name }}
                </option>
              </select>
            </div>

            <div class="mb-3" :class="{ invalid: !reason.isValid }">
              <label for="reason" class="form-label">Reason <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="reason.val" placeholder="Enter reason"
                @blur="clearValidity('reason')" />
            </div>

            <div class="mb-3" :class="{ invalid: !date.isValid }">
              <label for="date" class="form-label">Date <span class="text-danger">*</span></label>
              <input type="date" :min="startDate" :max="endDate" @change="getBookedSlot" v-model="dates"
                class="form-control" id="exampleInputdate" />
            </div>

            <div class="mb-3" :class="{ invalid: !time.isValid }">
              <!-- <label for="time" class="form-label">Time <span class="text-danger">*</span></label> -->
              <div v-for="slot in timeSlots" :key="slot" class="slot-item">
                <input type="radio" :id="slot" name="time" :value="slot" :disabled="booked.includes(slot)"
                  v-model="selectedSlot" />
                <label :for="slot"> {{ slot }}</label>
              </div>
            </div>

            <div class="mb-3" :class="{ invalid: !doctorComments.isValid }">
              <label for="doctorComments" class="form-label">Doctor Comments <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="doctorComments.val" placeholder="Enter Comments"
                @blur="clearValidity('doctorComments')" />
            </div>

            <!-- <div class="mb-3" :class="{ invalid: !status.isValid }">
                  <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" v-model="status.val" placeholder="Enter status"
                    @blur="clearValidity('status')" />
                </div> -->

            <div class="mb-3" :class="{ invalid: !status.isValid }">
              <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
              <select class="form-select" aria-label="size 3 select example" v-model="status.val"
                @blur="clearValidity('status')">
                <option value="new">New</option>
                <option value="accept">Accept</option>
                <option value="reject">Reject</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
